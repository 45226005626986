<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
        <div class="contents-box my-2">
          <div class="container py-3">
            <h3 class="h5 font-weight-bold">鍵ボックスから鍵を取り出します</h3>
            <p class="mb-4">
              鍵ボックスが設置された場所まで移動し、下記の手順で鍵を取り出しましょう。
            </p>
            <p class="mb-3"  v-if="!flowDetail['text'] == ''">
              <span class="badge bg-primary">詳細</span>
              {{flowDetail['text']}}</br />
            </p>
            <p class="mb-3">
              <span class="badge bg-primary">鍵ボックスイメージ画像</span>
              <img src="~navi/populife_input_password.png" class="w-100 rounded mb-2">

            </p>
            <p class="mb-3" v-if="!flowDetail['image1_url'] == ''">
              <span class="badge bg-primary">参考画像1</span>
              <img :src="flowDetail['image1_url']" class="w-100 rounded mb-2">

            </p>
            <p class="mb-3" v-if="!flowDetail['image2_url'] == ''">
              <span class="badge bg-primary">参考画像2</span>
              <img :src="flowDetail['image2_url']" class="w-100 rounded mb-2">

            </p>
            <p class="mb-3">
              <span class="badge bg-primary">注意</span><br />
              ・5回パスワードを間違えると5分間使用不可となります。ご注意ください。</br />
              ・指を挟まないようご注意ください。</br />
            </p>
        </div>
      </div>

      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}-1</h2>
      <div class="contents-box my-2">
        <div class="container pt-3">
          <h3 class="h5 font-weight-bold mb-3">以下の暗証番号を入力し、鍵ボックスから鍵を取り出します。</h3>
          <div class="step-flow pb-3">
            <h4 class="h6 font-weight-bold"><span class="count-icon">1</span>パスワード+ 右下の開錠ボタンを押します。</h4>
              <img src="~navi/populife_input_password.png" class="w-100 rounded mb-2">
            <div class="bg-primary p-2 text-center text-white">
              お客様の暗証番号：<br />
              <span class='font-weight-bold'>{{currentPw}}</span>
            </div>
            <div class="border p-2 mt-2">
              <p class="mb-2">パスワードを入力しても扉が開かないなど、鍵ボックスに関するトラブルはこちら</p>
              <p class="border-top pt-2 mb-1 small text-center">キーステーションカスタマーサポート連絡先</p>
              <div class="text-center mb-1">
                <a href="tel:05032013735" class="btn btn-outline-secondary rounded-pill px-5 py-2">
                  <span class="fas fa-phone-alt"></span>
                  050-3201-3735
                </a>
              </div>
            </div>
          </div>
          <div class="step-flow pb-3">
            <h4 class="h6 font-weight-bold"><span class="count-icon">2</span>ロックが解除され、扉が開きます</h4>
              <img src="~navi/populife_open.png" class="w-100 rounded mb-2">
          </div>
          <div class="step-flow pb-3">
            <h4 class="h6 font-weight-bold"><span class="count-icon">3</span>中から鍵を取り出します</h4>
              <img src="~navi/populife_pick_key.png" class="w-100 rounded mb-2">
          </div>
          <div class="step-flow pb-2">
            <h4 class="h6 font-weight-bold"><span class="count-icon">4</span>鍵を取り出したら、扉を閉めます</h4>
              <img src="~navi/populife_close.png" class="w-100 rounded mb-2">
          </div>
          <div class="step-flow pb-2">
            <h4 class="h6 font-weight-bold"><span class="count-icon">5</span>扉を完全に閉めると、自動でロックが掛かります</h4>
              <img src="~navi/populife_lock.png" class="w-100 rounded mb-2">
          </div>
        </div>
      </div>
    </main>
    <navi-footer @goToNextStep="onNextStepSelected" />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import NaviHeader from '@/components/navi/common/Header'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import Welcome from '@/components/navi/common/Welcome'
import NaviFooter from '@/components/navi/common/Footer'

export default {
  name: 'ReturnKeyToKs',
  components: { NaviHeader, RoomInfo, Step, NaviFooter, Welcome },
  props: ['flowDetail'],
  data() {
    return {
      populife: {
        name: '',
        password: '非表示'
      },
      currentStepInDB: 0
    }
  },
  mounted() {
    this.fetchPopulifePassword()
  },
  async activated() {
    await this.fetchCurrentStepInDB()
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep', 'reservationToken']),
    ...mapGetters('room', ['roomId']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    isFirstStep() {
      return this.reservationStep == 1
    },
    currentPw(){
      if(this.flowDetail.order == this.currentStepInDB){
        return this.populife.password
      }else{
        return '非表示'
      }
    },
  },
  methods: {
    onNextStepSelected() {
      this.$emit('goToNextStep')
    },
    onStepSelected() {
      this.$emit('setCurrentFlowDetail')
    },
    async fetchPopulifePassword() {
      try {
        const res = await axios.get(`/api/navi/reservations/${this.reservationToken}/populife_info`)
        this.populife.name = res.data.populife['name']
        this.populife.password = res.data.populife['current_pw']
      } catch(e) {
        alert(e)
      }
    },
    async fetchCurrentStepInDB() {
      try {
        const res = await axios.get(`/api/navi/reservations/${this.reservationToken}`)
        this.currentStepInDB = res.data.reservation.step
      } catch(e) {
        alert(e)
      }
    },
  }
}
</script>

<style scoped>
</style>


